
export const boxTypes = {
  hotel: 'hotel',
  day: 'day',
  generalInfo: 'general_info',
}

export const TRIP_TYPES = {
  all: 'all',
  flight: 'flight',
  bus: 'bus',
}

export default {
  TRIP_TYPES,
  boxTypes,
}
